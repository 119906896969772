import NavBar from "./components/NavBar.js";

import About from "./components/About.js";
import Thanks from "./components/Thanks.js";
import Contact from "./components/Contact.js";
import Home from "./components/Home.js";
import "./assets/css/app.min.css"; 
//import { AnimatePresence } from "framer-motion";
import { Router, Routes, Route, Navigate } from "react-router-dom";

function App() {
  return (
    <div className="container">
        <NavBar />
        <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about"  element={<About />} />
                <Route path="/contact"  element={<Contact />} />
                <Route path="/thanks"  element={<Thanks />} />
        </Routes>

    </div>
  );
}

export default App;
