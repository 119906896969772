import React from 'react';
import { NavLink } from "react-router-dom";
//import { motion } from "framer-motion";


import "../assets/css/navbar.min.css";


const NavBar = () => {
    return (
    <nav id="navbarSupportedContent">
        <div>
            
                <NavLink to="/about" id='about'>
                    <span className="icon">
                    <ion-icon name="person-outline"></ion-icon>
                    </span>
                    <span className="text">about</span>
                </NavLink>

                <NavLink end to="/" id='home'>
                    <span className="icon">
                    <ion-icon name="home-outline"></ion-icon>
                    </span>
                    <span className="text">Home</span>
                </NavLink>

                <NavLink to="/contact" id='contact'>
                    <span className="icon">
                    <ion-icon name="chatbubbles-outline"></ion-icon>
                    </span>
                    <span className="text">chat</span>
                </NavLink>

                <span className="indicator"></span>

        </div>

    </nav>
    );
}

export default NavBar;