import React from 'react'
import logo from "../assets/img/logo.png"
import "../assets/css/thanks.min.css";


export const Thanks = () => {
    return (
        <div className='thanks_container'>
            <div id='side_bar'>
                <h1 id='thanks'>Thank You</h1>
                <p>I'll do all that i can to take care of you. i can't to see if we are a good fit</p>
                <p>explore more?</p>
            </div>
            <span></span>
            <img id='bg' src={logo} alt="" />
        </div>
    )
}
export default Thanks;