
import "../assets/css/contact.min.css";
import logo_mark from "../assets/img/logo_mark.png";
import React, { useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import emailjs from '@emailjs/browser';





const Contact = () => {

   const navigate = useNavigate();

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_3k0u7wn', 'template_jvkhrcc', form.current, 'ebqygZFGnlnfS0eIX')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      navigate('/thanks');
  };

  return (
    <div className="contact_landing">
            <p>Lets get in touch</p>
            <p>Shall we see if I can help</p>
            <div className="contact_container">
                <img src={logo_mark} alt="" />
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" id="fullname" name="name" />
                    <label id="fullname-label" htmlFor="fullname">Name:</label>
                    <input type="email" id="email" name="reply_to" />
                    <label id="email-label" htmlFor="fullname">Email:</label>
                    <label id="service-label" htmlFor="service">I'm really in need of a photographer for a...</label>

                    <div className="toggle" id="service">

                        <input type="radio" id="wedding" name="service" value="wedding" defaultChecked/>
                        <label id="op1" For="wedding">Wedding</label>

                        <input type="radio" id="elopement" name="service" value="elopement" />
                        <label id="op2" For="elopement">Elopement</label>

                        <input type="radio" id="family" name="service" value="family" />
                        <label For="family">Family</label>

                        <div id="indicator" />
                    </div>
                    <label id="textarea-label" htmlFor="request">Tell me all about your needs.</label>
                    <textarea name="message" id="request" cols="30" rows="10" placeholder="..."></textarea>

                    <div className="submit-row">
                        <p>I can't wait to hear from you --</p>
                        <button type="submit">SEND</button>
                    </div>

                </form>
            </div>
        </div>
  );
}

export default Contact;

