import React from 'react';
//import { motion } from "framer-motion";


import "../assets/css/home.min.css";


import hero_image from "../assets/img/hero_image.png";
import logo_mark from "../assets/img/logo_mark.png";
import logo_text from "../assets/img/logo_text.png";
import fl1 from "../assets/img/fl1.png"
import fl2 from "../assets/img/fl2.png"


//const trans1 = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };
//const trans2 = { duration: .5, ease: [0.43, 0.13, 0.23, 0.96] };
//const trans3 = { duration: 0};


const Home = () => {
  return (
    <div className="landing">
      <div className="logo_container">
        <img src={logo_mark} alt="" />
      </div>
      <div className="hero_container">
        <span className="shape">
          <img src={hero_image} alt="" />
        </span>
        
        <img className="hero_text" src={logo_text} alt="" />
      </div>
      <div className="filter">
      <img src={fl1} alt="" />
      <img src={fl2} alt="" />
      </div>
    </div>
  );
}

export default Home;