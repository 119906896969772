import React from 'react'
import "../assets/css/about.min.css";
import about_img from "../assets/img/about.png"
import logo_mark from "../assets/img/logo_mark.png";

export const About = () => {
    return (
        <div className='about_container'>
            
            <div className='about_side'>

                <img src={logo_mark} alt="" />
                <div>
                    <p id='name'>Day of Fables</p>
                    <h1 >Jay Preston</h1>
                    <p id='job'>Photography creative</p>
                    <p id='para'>To Say where this whole journey began I would need to take you back to a core memory of myself as a little 8 yr old
                    running around my Granny's backyard with a barbie disposable camera I had just got for christmas. I loved it from the moment I got my hands on a 
                    camera and even more when I got my first roll of film handed to me. That love for photography has stayed with me through the years, the only difference now is you beautiful lovers are 
                    are filling my viewfiner and I love it. I feel so honoured to  be apart of some of the biggest memory making moments
                    of poeples lives and to capture the details that create the story so you can relive it. 
                    I'm so keen to grab a coffee with you and hear where your story all began with the how you met moment.</p>
                    <p id='end'>Jay x</p>
                </div>
            </div>
            <div className='img_side'>
                <span></span>
                <img src={about_img} alt="" />
            </div>
        </div>
    )
}
export default About;